import AgentCard from '@root/agents.joinroot.com/src/components/shared/agent-card';
import Banner, { bannerHeight } from '@root/agents.joinroot.com/src/components/shared/banner';
import ContinueButton from '@root/agents.joinroot.com/src/components/shared/continue-button';
import ErrorSummary from '@root/core/src/components/error-summary';
import Input, { TextTypes } from '@root/core/src/components/input';
import NetworkService from '@root/core/src/services/network-service';
import React from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useForm from '@root/core/src/hooks/use-form';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { isRequired, isValidEmail } from '@root/core/src/utils/validators';
import { sendForgotPassword } from '@root/agents.joinroot.com/src/api/root-server';
import { useState } from '@root/vendor/react';

const validations = [
  isRequired('email'),
  isValidEmail('email'),
];

export default function ForgotPassword() {
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isError, setIsError] = useState(false);
  const { trackClick } = useAnalytics('FORGOT_PASSWORD');

  const form = useForm({
    validations,
    initialValues: {
      email: '',
    },
  });

  const submit = async (e) => {
    e.preventDefault && e.preventDefault();
    setIsSending(true);
    setIsError(false);

    const email = form.getFieldProps('email').value;

    if (email) {
      const result = await NetworkService.request(sendForgotPassword, {
        password_reset: {
          email,
        },
      });

      if (result.isSuccess) {
        setIsSending(false);
        setIsSent(true);
      } else {
        setIsSending(false);
        setIsSent(false);
        setIsError(true);
      }
    }
  };

  const linkDisabled = isSending || isSent;
  let linkText = 'Send link';
  if (isSending) {
    linkText = 'Sending';
  } else if (isSent) {
    linkText = 'Sent';
  }

  let errorSummary = null;

  if (isError) {
    errorSummary = (
      <ErrorSummary
        heading={'There was an error sending the password reset link.'}
        message={'Please try again.'}
      />
    );
  }

  let content;
  if (!isSent) {
    content = (
      <>
        <h3>Reset your password.</h3>
        <form onSubmit={form.createSubmitHandler(submit)} >
          {errorSummary}
          <Input
            autoCapitalize={'off'}
            id={'email'}
            inputType={TextTypes.TEXT}
            label={'Email'}
            wrapperStyle={styles.inputWrapperStyle}
            {...form.getFieldProps('email')}
          />

          <ContinueButton
            disabled={linkDisabled}
            onClick={() => trackClick('SEND_LINK')}
          >
            {linkText}
          </ContinueButton>
        </form>
      </>
    );
  } else {
    content = (
      <>
        <h3>Check your email.</h3>
        A link to reset your password has been sent to the email you provided
      </>
    );
  }

  return (
    <div css={styles.wrapper}>
      <Banner />
      <div css={styles.container}>
        <AgentCard>
          {content}
        </AgentCard>
      </div>
    </div>
  );
}

ForgotPassword.propTypes = { };

const styles = StyleSheet.create({
  wrapper: {
    background: Colors.gray10(),
    color: Colors.nearBlack(),
    height: 'auto',
    minHeight: '100vh',
    paddingLeft: '1em',
  },
  inputWrapperStyle: {
    marginBottom: 10,
  },
  container: {
    display: 'flex',
    justifyContent: 'top',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
    paddingTop: bannerHeight,
  },
});
