import AuthService from '@root/core/src/services/auth-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useRef, useState } from '@root/vendor/react';
import SceneLoader from '@root/core/src/components/scene-loader';
import environment from '@root/core/src/utils/environment';
import useAccessToken from '@root/agents.joinroot.com/src/services/use-access-token';
import { expireSessionToken, sessionContext, validateSessionToken } from '@root/agents.joinroot.com/src/api/root-server';
import { useAgentDispatch } from '@root/agents.joinroot.com/src/contexts/agent-context';
import { useHistory } from '@root/vendor/react-router-dom';

export default function AuthController({
  ProtectedApp,
  PublicRouter,
}) {
  const history = useHistory();
  const agentDispatch = useAgentDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loggingIn, setIsLoggingIn] = useState(false);
  const [isValidating, setIsValidating] = useState(true);
  const { setAccessToken } = useAccessToken();
  const redirectOnLoginPath = useRef();

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);

    if (!isAuthenticated && !redirectOnLoginPath.current && searchParams.get('redirectOnLogin')) {
      redirectOnLoginPath.current = history.location.pathname;
    }
  }, [isAuthenticated, history.location]);

  const onLogin = (token) => {
    setIsLoggingIn(true);
    setAccessToken(token);
  };

  useEffect(() => {
    validateSessionToken()
      .then((data) => setIsAuthenticated(data?.valid || false))
      .catch(() => setIsAuthenticated(false))
      .finally(() => { setIsValidating(false); });
  }, [history.location.pathname, loggingIn]);

  useEffect(() => {
    if (isValidating) { return; }

    if (!isAuthenticated) {
      agentDispatch({
        type: 'clear',
      });
      return;
    }

    sessionContext()
      .then((data) => {
        AuthService.setCurrentUserContext({
          userId: data.agent.id,
        });
        agentDispatch({
          type: 'set',
          payload: data['agent'],
        });
      });
  }, [agentDispatch, history.location.pathname, isAuthenticated, isValidating]);

  const postExpireSessionToken = useCallback(async () => {
    await expireSessionToken();
  }, []);

  const passProps = {
    onLogin: () => {
      setIsLoggingIn(true);
    },
    onLogout: () => {
      postExpireSessionToken();
      AuthService.clear();
      setIsAuthenticated(false);
    },
  };

  if (isValidating) {
    return <SceneLoader hideHeader={true} />;
  }

  if (isAuthenticated) {
    return (
      <ProtectedApp
        inactiveTimeout={environment.inactiveTimeout}
        onLogout={passProps.onLogout}
        redirectOnLoginPath={redirectOnLoginPath.current}
      />
    );
  }

  return (
    <PublicRouter
      setAccessToken={onLogin}
    />);
}

AuthController.propTypes = {
  ProtectedApp: PropTypes.func.isRequired,
  PublicRouter: PropTypes.func.isRequired,
};
