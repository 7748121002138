import StorageService from '@root/core/src/services/storage-service';

export const DEFAULT_AGENT_CONTEXT = {};

export default class AgentSessionService {
  static CURRENT_AGENT_CONTEXT = 'agents/currentAgentContext';

  static setCurrentAgentContext(currentAgentContext) {
    return StorageService.setItem(this.CURRENT_AGENT_CONTEXT, currentAgentContext);
  }

  static getCurrentAgentContext() {
    return StorageService.getItem(this.CURRENT_AGENT_CONTEXT) || DEFAULT_AGENT_CONTEXT;
  }

  static clear() {
    StorageService.removeItem(this.CURRENT_AGENT_CONTEXT);
  }
}
