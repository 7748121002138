import AgentCard from '@root/agents.joinroot.com/src/components/shared/agent-card';
import Banner, { bannerHeight } from '@root/agents.joinroot.com/src/components/shared/banner';
import ContinueButton from '@root/agents.joinroot.com/src/components/shared/continue-button';
import ErrorSummary from '@root/core/src/components/error-summary';
import Input, { TextTypes } from '@root/core/src/components/input';
import Link from '@root/core/src/components/link';
import NetworkService from '@root/core/src/services/network-service';
import PropTypes from '@root/vendor/prop-types';
import useForm from '@root/core/src/hooks/use-form';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { agentPasswordReset, sendForgotPassword } from '@root/agents.joinroot.com/src/api/root-server';
import { isConfirmed, isRequired } from '@root/core/src/utils/validators';
import { useHistory } from '@root/vendor/react-router-dom';

function isValidPassword(minLength) {
  return (fieldName) => (object, errors) => {
    const fieldValue = object[fieldName];
    if (fieldValue && fieldValue.length < minLength) {
      errors[fieldName] = 'Password is too short';
    }
  };
}
import { useState } from '@root/vendor/react';

const validations = [
  isRequired('password'),
  isValidPassword(10)('password'),
  isConfirmed('password'),
  isRequired('passwordConfirmation'),
];

export default function PasswordReset({
  setAccessToken, token, email,
}) {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasClickedLink, setHasClickedLink] = useState(false);
  const history = useHistory();

  const form = useForm({
    validations,
    validateOnBlur: false,
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  const submit = async (e) => {
    e.preventDefault && e.preventDefault();
    const password = form.getFieldProps('password').value;
    const passwordConfirmation = form.getFieldProps('passwordConfirmation').value;

    if (password && passwordConfirmation) {
      const loginResult = await NetworkService.request(agentPasswordReset, {
        password_reset: {
          password,
          passwordConfirmation,
          token,
        },
      });

      if (loginResult.isSuccess) {
        if (loginResult.data.accessToken) {
          setAccessToken(loginResult.data.accessToken);
        } else {
          history.push('/');
        }
      } else {
        setErrorMessage(loginResult.error.message);
        setIsError(true);
      }
    }
  };

  let errorSummary = null;
  const RESET_TOKEN_EXPIRED = 'reset token expired';
  const RESET_TOKEN_INVALID = 'reset token invalid';

  const passwordResetResult = async () => {
    setHasClickedLink(true);
    return await NetworkService.request(sendForgotPassword, {
      password_reset: {
        email,
      },
    });
  };

  if (isError) {
    if (errorMessage === RESET_TOKEN_EXPIRED) {
      if (hasClickedLink) {
        errorSummary = (
          <ErrorSummary
            heading={''}
          >
            <h2 css={styles.heading}>Thanks! We&apos;ve just sent an email with a new link.</h2>
          </ErrorSummary>
        );
      } else {
        errorSummary = (
          <ErrorSummary
            heading={''}
          >
            <h2 css={styles.heading}>
              Your password reset link is expired.
              <Link
                disabled={hasClickedLink}
                onClick={passwordResetResult}
              >
                Click here
              </Link> to send a new link.
            </h2>
          </ErrorSummary>
        );
      }
    } else if (errorMessage === RESET_TOKEN_INVALID) {
      if (hasClickedLink) {
        errorSummary = (
          <ErrorSummary>
            <h2 css={styles.heading}>Thanks! We&apos;ve just sent an email with a new link.</h2>
          </ErrorSummary>
        );
      } else {
        errorSummary = (
          <ErrorSummary
            heading={''}
          >
            <h2 css={styles.heading}>
              Your password reset link is invalid.&nbsp;
              <Link
                disabled={hasClickedLink}
                onClick={passwordResetResult}
              >
                Click here
              </Link> to send a new link.
            </h2>
          </ErrorSummary>
        );
      }
    }
  }

  return (
    <div css={styles.wrapper}>
      <Banner />
      <div css={styles.container}>
        <AgentCard>
          <h3>Reset your password.</h3>
          <form
            onSubmit={form.createSubmitHandler(submit)}
          >
            {errorSummary}
            <Input
              autoCapitalize={'off'}
              id={'password'}
              inputType={TextTypes.PASSWORD}
              label={'New password (at least 10 characters)'}
              wrapperStyle={styles.inputWrapperStyle}
              {...form.getFieldProps('password')}
            />
            <Input
              autoCapitalize={'off'}
              id={'passwordConfirmation'}
              inputType={TextTypes.PASSWORD}
              label={'Re-enter new password'}
              wrapperStyle={styles.inputWrapperStyle}
              {...form.getFieldProps('passwordConfirmation')}
            />

            <ContinueButton disabled={hasClickedLink}>
              Change password
            </ContinueButton>
          </form>
        </AgentCard>
      </div>
    </div>
  );
}

PasswordReset.propTypes = {
  email: PropTypes.string,
  setAccessToken: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  wrapper: {
    background: Colors.gray10(),
    color: Colors.nearBlack(),
    height: 'auto',
    minHeight: '100vh',
    paddingLeft: '1em',
  },
  inputWrapperStyle: {
    marginBottom: 10,
  },
  container: {
    display: 'flex',
    justifyContent: 'top',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
    paddingTop: bannerHeight,
  },

  heading: {
    ...Theme.boldCaptionTitle(),
  },
});
