import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useState } from '@root/vendor/react';
import { RootError } from '@root-common/root-errors';

export const PRODUCT_CHOICES = {
  CLASSIC: 'classic',
  DAY_ZERO: 'day_zero',
  CUSTOMIZE_QUOTE: 'customize_quote',
};

const ProductChoiceStateContext = React.createContext();
const ProductChoiceSetStateContext = React.createContext();

function ProductChoiceProvider({ value, children }) {
  const [state, setState] = useState(value);

  return (
    <ProductChoiceStateContext.Provider value={state}>
      <ProductChoiceSetStateContext.Provider value={setState}>
        {children}
      </ProductChoiceSetStateContext.Provider>
    </ProductChoiceStateContext.Provider>
  );
}

ProductChoiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.any.isRequired,
};

function useProductChoiceState() {
  const context = React.useContext(ProductChoiceStateContext);
  if (context === undefined) {
    throw new RootError({
      message: 'useProductChoiceState must be used within a ProductChoiceProvider',
      name: 'ProductChoiceProviderError',
    });
  }
  return context;
}

function useProductChoiceSetState() {
  const context = React.useContext(ProductChoiceSetStateContext);
  if (context === undefined) {
    throw new RootError({
      message: 'useProductChoiceSetState must be used within a ProductChoiceProvider',
      name: 'ProductChoiceProviderError',
    });
  }
  return context;
}

function useProductChoice() {
  const setState = useProductChoiceSetState();

  const updateProductChoice = useCallback((newProductChoice) => {
    sessionStorage.setItem('productChoice', JSON.stringify(newProductChoice));
    setState(newProductChoice);
  }, [setState]);

  return [useProductChoiceState(), updateProductChoice];
}

export { ProductChoiceProvider, useProductChoice };
