import ForgotPassword from '@root/agents.joinroot.com/src/scenes/forgot-password';
import PasswordReset from '@root/agents.joinroot.com/src/scenes/password-reset';
import PropTypes from '@root/vendor/prop-types';
import React, { lazy } from '@root/vendor/react';
import { Redirect, Route, Switch } from '@root/core/src/components/route';
import { useLocation } from '@root/vendor/react-router-dom';

const Login = lazy(() =>
  import(
    /* webpackChunkName: "addclient" */ '@root/agents.joinroot.com/src/scenes/login'
  )
);
import(
  /* webpackChunkName: "poller-animation", webpackPrefetch: true */ '@root/core/src/assets/animations/road'
);

export default function PublicRouter({ setAccessToken }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('password-reset-token') || '';
  const email = searchParams.get('email') || '';

  return (
    <Switch>
      <Route
        path={'/login'}
        render={() => (
          <Login
            setAccessToken={setAccessToken}
          />
        )}
      />
      <Route
        path={'/password-reset'}
        render={() => (
          <PasswordReset
            email={email}
            setAccessToken={setAccessToken}
            token={token}
          />
        )}
      />
      <Route
        path={'/forgot-password'}
        render={() =>
          <ForgotPassword />
        }
      />
      <Redirect to={'/login'} />
    </Switch>
  );
}

PublicRouter.propTypes = {
  setAccessToken: PropTypes.func.isRequired,
};
