import PropTypes from '@root/vendor/prop-types';
import React, { lazy } from '@root/vendor/react';
import { Redirect, Route, Switch } from '@root/core/src/components/route';

const WelcomeEntry = lazy(() => import(/* webpackChunkName: "welcome" */ '@root/agents.joinroot.com/src/pages/welcome-entry'));
const PolicyEntry = lazy(() => import(/* webpackChunkName: "policy" */ '@root/agents.joinroot.com/src/pages/policy-entry'));
const DeeplinkEntry = lazy(() => import(/* webpackChunkName: "policy" */ '@root/agents.joinroot.com/src/pages/deeplink-entry'));
const AccountEntry = lazy(() => import(/* webpackChunkName: "account" */ '@root/agents.joinroot.com/src/pages/account-entry'));
const ProfileEntry = lazy(() => import(/* webpackChunkName: "account" */ '@root/agents.joinroot.com/src/pages/profile-entry'));
const QuoteEntry = lazy(() => import(/* webpackChunkName: "account" */ '@root/agents.joinroot.com/src/pages/quote-entry'));

export default function ProtectedRouter({ onLogout }) {
  return (
    <Switch>
      <Route
        path={'/welcome'}
        render={() => <WelcomeEntry onLogout={onLogout} />}
      />
      <Route
        path={'/deeplink'}
        render={() => <DeeplinkEntry />}
      />
      <Route
        path={'/client/:userId/policy'}
        render={({ match }) => (
          <PolicyEntry
            onLogout={onLogout}
            {...match.params}
          />)}
      />
      <Route
        path={'/client/add'}
        render={() => <Redirect to={'/client-account'} />}
      />
      <Route
        path={'/client-account'}
        render={() => <AccountEntry />}
      />
      <Route
        path={'/client-profile'}
        render={() => <ProfileEntry />}
      />
      <Route
        path={'/client-quote'}
        render={() => <QuoteEntry />}
      />
      <Redirect to={'/welcome/policies'} />
    </Switch>
  );
}

ProtectedRouter.propTypes = {
  onLogout: PropTypes.func.isRequired,
  redirectRoute: PropTypes.string,
};
