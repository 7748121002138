import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import Shadows from '@root/brand/src/utils/shadows';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function Card({
  children, className, cssOverrides,
}) {
  return (
    <div
      className={className}
      css={[styles.container, cssOverrides]}
    >
      {children}
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  cssOverrides: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const styles = StyleSheet.create({
  container: {
    ...Responsive.md({
      ...Shadows.cardShadow(),
      padding: 100,
      background: Colors.white(),
    }),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '10px',
    top: 62,
    boxShadow: 'none',
    padding: 10,
  },
});
