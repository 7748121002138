import AnalyticsProvider from '@root/core/src/contexts/analytics/analytics-provider';
import AuthController from '@root/agents.joinroot.com/src/components/auth-controller';
import ErrorBoundary from '@root/core/src/components/error-boundary';
import FormProvider from '@root/core/src/contexts/form/form-provider';
import PropTypes from '@root/vendor/prop-types';
import ProtectedApp from '@root/agents.joinroot.com/src/components/protected-app';
import PublicRouter from '@root/agents.joinroot.com/src/components/routers/public-router';
import SceneLoader from '@root/core/src/components/scene-loader';
import environment from '@root/core/src/utils/environment';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { AgentProvider } from '@root/agents.joinroot.com/src/contexts/agent-context';
import { PRODUCT_CHOICES, ProductChoiceProvider } from '@root/agents.joinroot.com/src/contexts/product-choice-context';
import { Suspense } from '@root/vendor/react';
import { datadogRum } from '@root/vendor/@datadog/browser-rum';

if (environment.dataDogEnabled) {
  datadogRum.init({
    applicationId: environment.dataDogApplicationId,
    clientToken: environment.dataDogClientToken,
    site: 'datadoghq.com',
    service: 'agents.joinroot.com',
    env: environment.environment,
    version: environment.releaseSha,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [/https?:\/\/localhost(:\d+)?/, /https?:\/\/(.+\.)?joinroot.com(:\d+)?/],
    trackSessionAcrossSubdomains: true,
  });

  datadogRum.startSessionReplayRecording();
}

function App({ routerComponent: Router }) {
  useAnalytics('APP_ENTRY');
  const productChoice = JSON.parse(sessionStorage.getItem('productChoice')) || PRODUCT_CHOICES.DAY_ZERO;

  return (
    <Router>
      <Suspense fallback={<SceneLoader hideHeader={true} />}>
        <AgentProvider>
          <ProductChoiceProvider value={productChoice}>
            <AuthController
              ProtectedApp={ProtectedApp}
              PublicRouter={PublicRouter}
            />
          </ProductChoiceProvider>
        </AgentProvider>
      </Suspense>
    </Router>
  );
}

App.propTypes = {
  routerComponent: PropTypes.func.isRequired,
};

export default function AppEntry({ routerComponent }) {
  return (
    <ErrorBoundary
      errorTextSuffix={'Please share this screenshot with us, and some information on how you ran into this error.'}
      showHeader={false}
      showTimestamp={true}
    >
      <FormProvider>
        <AnalyticsProvider eventPrefix={'ROOT_AGENTS'}>
          <App routerComponent={routerComponent} />
        </AnalyticsProvider>
      </FormProvider>
    </ErrorBoundary>
  );
}

AppEntry.propTypes = {
  routerComponent: PropTypes.func.isRequired,
  routerProps: PropTypes.object,
};

const ROOT_AGENTS_APP_DOMAIN = 'agents.joinroot.com';
export { ROOT_AGENTS_APP_DOMAIN };
