import Card from '@root/core/src/components/card';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import Shadows from '@root/brand/src/utils/shadows';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function AgentCard({ children, cssOverrides = {} }) {
  return (
    <Card cssOverrides={[styles.cardStyle, cssOverrides]}>
      {children}
    </Card>
  );
}

AgentCard.propTypes = {
  children: PropTypes.node.isRequired,
  cssOverrides: PropTypes.object,
};

const styles = StyleSheet.create({
  cardStyle: {
    ...Responsive.md({
      padding: 24,
      width: 'auto',
      background: Colors.white(),
    }),
    ...Shadows.cardShadow(),
    padding: 24,
    background: Colors.white(),
    left: 0,
    right: 0,
    margin: 'auto',
    display: 'flex',
    maxWidth: 480,
    minWidth: 480,
    width: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    borderRadius: 4,
    p: {
      color: Colors.nearBlack(),
    },
  },
});
