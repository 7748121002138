import AppEntry from '@root/agents.joinroot.com/src/app-entry';
import ErrorReportService from '@root/core/src/services/error-report-service';
import React from '@root/vendor/react';
import ReactDOM from '@root/vendor/react-dom';
import environment from '@root/core/src/utils/environment';
import { BrowserRouter } from '@root/vendor/react-router-dom';

if (Object.entries && window.URLSearchParams) {
  ErrorReportService.init({ environment });

  ReactDOM.render(
    <AppEntry routerComponent={BrowserRouter} />,
    document.getElementById('root')
  );
}
