import PropTypes from '@root/vendor/prop-types';
import ProtectedRouter from '@root/agents.joinroot.com/src/components/routers/protected-router';
import React, { useEffect, useRef } from '@root/vendor/react';
import { useHistory } from '@root/vendor/react-router-dom';
import { validateSessionToken } from '@root/agents.joinroot.com/src/api/root-server';

export default function ProtectedApp({
  onLogout,
  pingInterval = 10000,
  redirectOnLoginPath,
}) {
  const isMounted = useRef(false);
  const history = useHistory();

  useEffect(() => {
    if (redirectOnLoginPath && !isMounted.current) {
      history.replace(redirectOnLoginPath);
    }

    isMounted.current = true;

    return () => isMounted.current = false;
  });

  useEffect(() => {
    const pingForToken = async () => {
      try {
        const data = await validateSessionToken();

        if (!data.valid && isMounted.current) {
          onLogout();
        }
      } catch (e) {
        // If there is a bad network connection `fetch` will throw a TypeError
        // when trying to make a request. We want to trap this error here since
        // we dont want to show the error page when there is a bad connection.
        if (!(e instanceof TypeError)) {
          throw e;
        }
      }
    };

    const interval = setInterval(pingForToken, pingInterval);
    return () => clearInterval(interval);
  }, [onLogout, pingInterval]);

  return <ProtectedRouter onLogout={onLogout} />;
}

ProtectedApp.propTypes = {
  onLogout: PropTypes.func.isRequired,
  pingInterval: PropTypes.number,
  redirectOnLoginPath: PropTypes.string,
};
