import Button from '@root/core/src/components/button';
import PropTypes from '@root/vendor/prop-types';
import Shadows from '@root/brand/src/utils/shadows';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function ContinueButton({
  disabled = false, children, onClick = () => {},
}) {
  return (
    <Button
      css={styles.continueButton}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

ContinueButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

const styles = StyleSheet.create({
  continueButton: {
    ...Shadows.softShadow(),
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    fontSize: '14px',
    padding: '8px 0',
    height: '36px',
    minHeight: '36px',
    maxHeight: '36px',
  },
});
