import Colors from '@root/brand/src/utils/colors';
import React from '@root/vendor/react';
import rSquare from '@root/agents.joinroot.com/src/assets/r-square.svg';
import { StyleSheet } from '@root/core/src/utils/styles';
import { light, semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export const bannerHeight = '100px';

export default function Banner() {
  return (
    <div
      css={styles.bannerContainer}
      data-testid={'agents-banner'}
    >
      <img src={rSquare} />
      <div css={styles.brandTitle}>
        <div css={styles.header}>Root Agent Platform</div>
        <div css={styles.headerSubtext}>Root Insurance</div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  bannerContainer: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flex: 1,
    height: 60,
    alignItems: 'flex-start',
    img: {
      marginLeft: 16,
      marginTop: 16,
      height: 32,
      width: 32,
    },
    zIndex: 2,
    backgroundColor: Colors.gray10(),
  },
  brandTitle: {
    marginLeft: 16,
    marginTop: 12,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  header: {
    ...semiBold(),
    fontSize: 16,
    marginBottom: -3,
    verticalAlign: 'text-top',
  },
  headerSubtext: {
    ...light(),
    fontSize: 12,
    verticalAlign: 'text-top',
  },
});
